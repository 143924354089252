.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selectedFileWrapper {
  text-align: center;
}

.selectedFile {
  color: #000;
  font-weight: bold;
}

.modalStyle {
  @extend .rounded-4;
  @extend .p-2;
  background-color: #eeeeee !important;
}

@import "../../../../css/index.scss";
