.modalStyle {
    @extend .rounded-4;
    @extend .p-2;
    background-color: #eeeeee !important;
  }
  
  .active {
    background-color: #79bdd7 !important;
    color: #ffffff !important;
    font-size: larger !important;
    font-weight: bold !important;
  }

  .empty{
    background-color: #79bdd7 !important;
    color: #79bdd7 !important;
  }
  
  .header {
    background-color: #104e99 !important;
    color: white;
    font-size: larger !important;
    font-weight: bold !important;
  }
  
  .actionHeader {
    background-color: #525252 !important;
    color: #525252 !important;
  }
  
  @import "../../../css/index.scss";
  