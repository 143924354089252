.QCard {
  @extend .rounded-4;
  background-color: transparent;
  padding: 10px;
  padding-bottom: 20px;
}

.OptionCard {
  @extend .QCard;
  background-color: #f8f9fd;
  padding: 10px;
  // margin-top: 10px;
}

.optionName {
  @extend .rounded-4;
  background-color: #e5e5e5;
  color: #9aa0b2;
}

.active {
  .OptionCard {
    @extend .bg-secondary;
    color: #ffffff;
  }

  .optionName {
    background-color: #0089af;
    color: #ffffff;
  }
}

.correct {
  .OptionCard {
    @extend .bg-success;
    color: #ffffff;
  }

  .optionName {
    background-color: #005c29;
    color: #ffffff;
  }
}

.incorrect {
  .OptionCard {
    @extend .bg-danger;
    color: #ffffff;
  }

  .optionName {
    background-color: #da0c0c;
    color: #ffffff;
  }
}

.iconButton {
  color: #9aa0b2;
}

// TODO: make the question image modal adapt to the image width

.questionImageModal {
  max-width: 100% !important;
  margin-left: 10px;
  margin-right: 10px;
}

.white-area::-webkit-scrollbar {
  display: none !important;
}

@import "../../css/index.scss";