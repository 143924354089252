/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
    min-height: 30px;
  width: 100% !important;

  }
}

.ProseMirror {
  outline: none;
  width: 100% !important;

}

.ProseMirror p {
  //  TODO: separate styles for view and edit screen.
  margin-top: 5px;
  padding: 10px !important;
  margin-bottom: 0 !important;
  width: 100% !important;
}

.math-component {
  position: relative;
  bottom: 0px;
  background: transparent;
  cursor: pointer;

  p {
    margin: 0px !important;
    padding: 0px !important;
  }
}

.edit-button {
  z-index: 100;
  position: relative;
  margin-top: -20px;
  margin-left: -15px;
}

@-moz-document url-prefix() {
  .math-component {
    bottom: 8px;
  }
}

.modal-backdrop {
  z-index: 90 !important;
}

.modal {
  z-index: 100 !important;
}

.close-button {
  position: absolute;
  right: 0;
}
