body, #app, #app > div {
  height: fit-content !important;
}

.printComponent {
  @media print {
    display: block !important;
    break-inside: avoid;
    padding-top: 5%;
  }
}
