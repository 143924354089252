$primary: #373d54;
$primary-dark: #1f2330;
$secondary: #01b9e1;
$body-bg: #141930;
$button-bg-light: #c4c4c4;
$button-panel-bg: #eeeeee;

html,
body,
#app,
#app > div {
  height: 100%;
  overflow-x: hidden;
}

.rounded-bottom-4 {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.rounded-top-4 {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNTAiIGhlaWdodD0iNTAiCnZpZXdCb3g9IjAgMCA1MCA1MCIKc3R5bGU9IiBmaWxsOiNmZmZmZmY7Ij48cGF0aCBkPSJNIDAgOSBMIDAgMTEgTCA1MCAxMSBMIDUwIDkgWiBNIDAgMjQgTCAwIDI2IEwgNTAgMjYgTCA1MCAyNCBaIE0gMCAzOSBMIDAgNDEgTCA1MCA0MSBMIDUwIDM5IFoiPjwvcGF0aD48L3N2Zz4=") !important;
}

.rounded-4 {
  border-radius: 20px !important;
}

.bg-button {
  background-color: $button-bg-light !important;
}

.nav-link {
  @extend .btn;
  border-radius: 20px !important;
  color: white !important;
  margin-right: 10px !important;
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.nav-link:hover {
  color: white !important;
}

.nav-link:active {
  color: white !important;
  background-color: $secondary !important;
}

.left-nav {
  float: left;
  clear: left;
}
.left-nav:hover {
  background-color: #01b9e1;
}

.bg-card {
  background-color: #e5e5e5 !important;
}

.bg-button-panel {
  background-color: #eeeeee !important;
}

.bg-review {
  background-color: #3521b6 !important;
}

.field-color {
  color: #70737b;
}

.white-area::-webkit-scrollbar {
  display: none !important;
}

.white-area {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

@import "../views/question/Components/QuestionButton/QuestionButton.module.scss";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
