.modalStyle {
  @extend .rounded-4;
  @extend .p-2;
  background-color: #eeeeee !important;
}

.active {
  background-color: #47B5FF !important;
  color: #ffffff !important;
}

.header {
  background-color: #525252 !important;
  color: white;
  font-size: larger !important;
  font-weight: bold !important;
}

.actionHeader {
  background-color: #525252 !important;
  color: #525252 !important;
}

@import "../../css/index.scss";
