.bg-card {
  background-color: #eeeeee !important;
}

.tabStyle {
  @extend .w-100;
  @extend .px-4;
  @extend .py-3;
  @extend .rounded-4;
  @extend .bg-card;
  @extend .border-0;
  @extend .text-black;
}

.tabStyle:focus {
  outline: none !important;
  box-shadow: none !important;
}

.actionHeader {
  background-color: #525252 !important;
  color: #525252 !important;
}

.activeTabStyle {
  @extend .tabStyle;
  @extend .bg-info;
  @extend .text-white;
}

.tabContainerStyle {
  @extend .bg-card;
  @extend .m-2;
  @extend .rounded-4;
}

.modalStyle {
  @extend .rounded-4;
  @extend .p-2;
  background-color: #eeeeee !important;
}

.batchListElementStyle {
  @extend .w-100;
  @extend .p-4;
  @extend .rounded-4;
  @extend .border-0;
  @extend .text-black;
  @extend .text-start;
  box-shadow: none;
  border-style: none !important;
  background-color: #eeeeee !important;
}

.batchListElementSubButtonStyle {
  @extend .bg-black;
  @extend .rounded-4;
}

// Left Section
.BatchListContainer {
  @extend .d-flex;
  @extend .flex-column;
  @extend .rounded-4;
  @extend .text-center;
  @extend .align-items-center;
  /* Hide scrollbar for IE, Edge and Firefox */
  //   -ms-overflow-style: none;  /* IE and Edge */
  //   scrollbar-width: none;  /* Firefox */
}

// .BatchListContainer::-webkit-scrollbar {
//     display: none;
// }

.tableRow {
  text-align: center;
  @extend .h-25;
}

.tableRow:hover {
  background-color: gainsboro;
}

.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selectedFileWrapper {
  text-align: center;
}

.selectedFile {
  color: #000;
  font-weight: bold;
}

.batchListElement {
  @extend .rounded-4;
  @extend .border-0;
  @extend .text-black;
  @extend .mt-4;
  @extend .py-4;
  @extend .w-75;
  @extend .bg-card;
  border-style: none !important;
  outline: none !important;
}

.batchListElementSelected {
  @extend .batchListElement;
  @extend .bg-info;
  @extend .py-0;
  @extend .text-white;
}

.threeDots:after {
  content: "\2807";
  @extend .bg-info;
}

@import "../../css/index.scss";
